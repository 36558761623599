import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";

const SignupScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accountType = location.state?.accountType || "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.,-_]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = () => {
    let valid = true;
    let newErrors = { email: "", password: "" };

    if (!validateEmail(email)) {
      valid = false;
      newErrors.email = "L'indirizzo email non è valido.";
    }

    if (!validatePassword(password)) {
      valid = false;
      newErrors.password =
        "La password deve avere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale.";
    }

    setErrors(newErrors);

    if (valid) {
      navigate("/details", {
        state: { accountType, email, password },
      });
    }
  };

  return (
    <>
      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
        }}
      >
        <Spacer height={20} />
        <h1
          style={{
            color: "#222",
            textAlign: "left",
            fontSize: scaleWidth(26),
          }}
        >
          Imposta le credenziali dell’Account Host
        </h1>
        <Spacer height={10} />
        <p style={{ color: "#717171", fontSize: fontScale(17) }}>
          Per accedere alla nostra app, è necessario effettuare la registrazione
          utilizzando le vostre credenziali personali. Questo ci permette di
          garantirvi un servizio sicuro.
        </p>
        <Spacer height={40} />
        <Input
          label={"Email"}
          placeholder="Indirizzo email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {errors.email}
          </p>
        )}
        <Spacer height={30} />
        <Input
          label={"Password"}
          placeholder="Imposta password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errors.password && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {errors.password}
          </p>
        )}
        <Spacer height={10} />
        <p
          style={{
            fontSize: fontScale(15),
            color: "#717171",
            fontWeight: 300,
          }}
        >
          <i>
            *almeno 8 caratteri
            <br />
            *almeno una lettera maiuscola
            <br />
            *almeno una lettera minuscola
            <br />
            *almeno un carattere speciale tra: @$!%*?&#.,-_
            <br />
            *almeno un numero
          </i>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          bottom: scaleWidth(10),
          width: scaleWidth(280),
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          borderTopWidth: 1,
          borderTopColor: "#D9D9D9",
        }}
      >
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Button type="info" onClick={() => navigate("/")}>
            Indietro
          </Button>
        </div>
        <Spacer />
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Button type="action" onClick={handleSubmit}>
            Prosegui
          </Button>
        </div>
      </div>
    </>
  );
};

export default SignupScreen;
