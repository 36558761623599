import "./NotificationsScreen.css"; // Import your CSS for styling

import React, { useEffect, useState } from "react";

import { FaEllipsisVertical } from "react-icons/fa6";
import Header from "../../../components/Header";
import Spacer from "../../../components/Spacer";

// import { getAllItems } from "../../../services/apiService";

// import { useNavigate } from "react-router-dom";

const NotificationsScreen = () => {
  // const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // const response = await getAllItems("notifications");
        // console.log("Fetched notifications:", response.data);
        setNotifications([]);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="notifications-screen">
      <Header title={"Centro notifiche"} />
      <div className="notifications-list">
        <Spacer height={20} />

        {notifications.length > 0 ? (
          notifications.map((local) => (
            <div key={local.id} className="local-card">
              <img
                src={local.logoUrl}
                alt={"Logo " + local.name}
                className="local-logo"
              />
              <div className="local-info">
                <h2 className="local-name">{local.name}</h2>
                <p className="local-category">{local.address}</p>
              </div>
              <div className="local-options">
                <FaEllipsisVertical />
              </div>
            </div>
          ))
        ) : (
          <div>
            <p>Nessuna notifica</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsScreen;
