import { fontScale, scaleWidth } from "../utils/responsive";

import { FaTrash } from "react-icons/fa";
import React from "react";
import Spacer from "./Spacer";

const LocalCard = ({ local, onClick, onDelete }) => {
  const styles = {
    localCard: {
      display: "flex",
      alignItems: " center",
      justifyContent: "flex-start",
      backgroundColor: "#ffffff",
      padding: scaleWidth(10),
      borderRadius: scaleWidth(10),
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      maxWidth: scaleWidth(400),
      margin: `${scaleWidth(20)}px ${scaleWidth(30)}px`,
    },
    localInfo: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    localLogo: {
      width: scaleWidth(70),
      height: scaleWidth(70),
      borderRadius: scaleWidth(10),
      objectFit: "cover",
    },
    localName: {
      fontSize: fontScale(20),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    localCategory: {
      fontSize: fontScale(19),
      color: "#666",
    },
    localOptions: {
      color: "#ff4d4d",
      fontSize: fontScale(18),
      cursor: "pointer",
      padding: scaleWidth(5),
      transition: "color 0.2s",
    },
    localOptionsHover: {
      color: "#ff1a1a",
    },
    spacer: {
      flex: 1,
    },
  };

  const getType = (type) => {
    switch (type) {
      case "bar":
        return "Bar";
      case "lounge":
        return "Lounge Bar";
      case "disco":
        return "Discoteca";
      default:
        return "Altro";
    }
  };

  return (
    <div style={styles.localCard} onClick={onClick}>
      <img
        src={process.env.REACT_APP_BASE_MEDIA_URL + local.logoUrl}
        alt={"Logo " + local.name}
        style={styles.localLogo}
      />
      <Spacer width={18} />
      <div style={styles.localInfo}>
        <h2 style={styles.localName}>{local.name}</h2>
        <Spacer height={6} />
        <p style={styles.localCategory}>{getType(local.type)}</p>
      </div>
      <div style={styles.spacer} />

      <div
        style={styles.localOptions}
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering the card's onClick
          onDelete(local.id);
        }}
        onMouseOver={(e) =>
          (e.target.style.color = styles.localOptionsHover.color)
        }
        onMouseOut={(e) => (e.target.style.color = styles.localOptions.color)}
      >
        <FaTrash />
      </div>
    </div>
  );
};

export default LocalCard;
