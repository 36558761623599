// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyConj212Bu-P9rVlcKGLuYcEQpazlefxSE",
  authDomain: "app-evento-6ce0e.firebaseapp.com",
  projectId: "app-evento-6ce0e",
  storageBucket: "app-evento-6ce0e.appspot.com",
  messagingSenderId: "357153336274",
  appId: "1:357153336274:web:18d4a7b0e288c852e1b60b",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
