import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import { FaArrowLeft } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import axios from "axios";
import categories from "../../../utils/categories";
import { updateItem } from "../../../services/apiService"; // Adjust the import according to your service

const EditEventScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventData = location.state?.eventData || {};
  const local = eventData.local || {};

  console.log("Event data:", eventData.host || eventData.hostId);

  const [formData, setFormData] = useState({
    id: eventData.id || "",
    name: eventData.name || "",
    localId: local.id || "",
    address: eventData.address || "",
    date: eventData.date ? eventData.date.split("T")[0] : "", // Extract the date part
    endDate: eventData.endDate ? eventData.endDate.split("T")[0] : "",
    startTime: eventData.startTime || "",
    endTime: eventData.endTime || "",
    price: eventData.price || "",
    parkingPrice: eventData.parkingPrice || "",
    category: eventData.category || "",
    categories: eventData.categories || [],
    tags: eventData.tags || [],
    subcategory: eventData.subcategory || "",
    description: eventData.description || "",
    imgUrl: eventData.imgUrl || "",
    parking: eventData.parking || [],
    isParkingSecure: eventData.isParkingSecure || false,
    parkingAddress: eventData.parkingAddress || "",
    hasEndDate: eventData.endDate && eventData.endDate !== "" ? false : true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleParkingChange = (e) => {
    const { name, checked } = e.target;
    let updatedParking = [...formData.parking];

    if (checked) {
      updatedParking.push(name);
    } else {
      updatedParking = updatedParking.filter((item) => item !== name);
    }

    setFormData({
      ...formData,
      parking: updatedParking,
      parkingPrice: updatedParking.includes("gratuito")
        ? 0
        : formData.parkingPrice,
      isParkingSecure: updatedParking.includes("custodito"),
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        imgUrl: `/media/${response.data.filename}`, // Save the image URL
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleCategoryClick = (category) => {
    setFormData((prevState) => {
      const isSelected = prevState.categories.includes(category);
      const newCategories = isSelected
        ? prevState.categories.filter((cat) => cat !== category)
        : prevState.categories.length < 2
        ? [...prevState.categories, category]
        : prevState.categories;
      return { ...prevState, categories: newCategories };
    });
  };

  const handleTagClick = (tag) => {
    setFormData((prevState) => {
      const isSelected = prevState.tags.includes(tag);
      const newTags = isSelected
        ? prevState.tags.filter((t) => t !== tag) // Remove the tag if it's already selected
        : [...prevState.tags, tag]; // Add the tag if it's not selected
      return { ...prevState, tags: newTags };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEventData = {
      name: formData.name,
      hostId: formData.host,
      localId: local.id,
      address: formData.address,
      date: formData.date,
      endDate: formData.hasEndDate ? formData.endDate : null,
      startTime: formData.startTime,
      endTime: formData.endTime,
      price: parseFloat(formData.price),
      parkingPrice: parseFloat(formData.parkingPrice),
      category: formData.category,
      subcategory: formData.subcategory,
      description: formData.description,
      imgUrl: formData.imgUrl,
      parking: formData.parking,
      isParkingSecure: formData.isParkingSecure,
    };

    console.log("Updating event:", formData);

    updateItem(`events/`, formData.id, updatedEventData)
      .then((response) => {
        console.log("Event updated successfully:", response.data);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error updating event:", error);
      });
  };

  return (
    <div>
      <div style={{ textAlign: "center", backgroundColor: "#f7f7f7" }}>
        <FaArrowLeft
          style={{
            position: "absolute",
            top: scaleWidth(28),
            left: scaleWidth(10),
            cursor: "pointer",
            color: "black",
            fontSize: fontScale(20),
          }}
          onClick={() => navigate(-1)}
        />
        <Spacer height={20} />
        {formData.imgUrl ? (
          <img
            style={{ width: scaleWidth(143), height: "auto", borderRadius: 10 }}
            src={process.env.REACT_APP_BASE_MEDIA_URL + formData.imgUrl}
            alt="Event"
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: scaleWidth(165),
                height: scaleWidth(233),
                borderRadius: 10,
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <FaUpload size={50} color="#ccc" />
            </div>
          </div>
        )}
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{
            display: "none",
          }}
        />
        <Spacer height={20} />
      </div>
      <h1
        style={{
          backgroundColor: "#fff",
          color: "black",
          textAlign: "center",
          padding: scaleWidth(15),
          backgroundSize: "cover",
          borderBottom: "3px solid #4BC592", // Added bottom green border
        }}
      >
        {formData.name}
      </h1>

      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          paddingTop: scaleWidth(30),
        }}
      >
        <form onSubmit={handleSubmit}>
          <Input
            label="Titolo dell'evento"
            placeholder="Inserisci titolo"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <Spacer height={20} />

          <Input
            label="Luogo dell'evento"
            placeholder="Inserisci il luogo dell'evento"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />

          <Spacer height={20} />
          <Input
            label="Giorno/i di svolgimento"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="hasEndDate"
              name="hasEndDate"
              checked={formData.hasEndDate}
              onChange={handleChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />
            <label htmlFor="hasEndDate" style={{ fontSize: fontScale(17) }}>
              L'evento si conclude il giorno stesso
            </label>
          </div>
          {!formData.hasEndDate && (
            <>
              <Spacer height={20} />
              <Input
                label="Data di fine"
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </>
          )}
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              label="Ora di inizio"
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
            />
            <Spacer width={20} />
            <Input
              label="Ora di fine"
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
            />
          </div>
          <Spacer height={20} />
          <Input
            label="Prezzo"
            placeholder="Inserisci il prezzo dell'evento"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
          <Spacer height={30} />

          <label
            style={{
              color: "#222",
              fontSize: fontScale(18),
              marginBottom: scaleWidth(5),
              marginLeft: scaleWidth(5),
            }}
          >
            Indicazioni parcheggio (se presente)
          </label>
          <Spacer height={10} />
          <Input
            label=""
            placeholder="Inserisci il prezzo del parcheggio"
            type="number"
            name="parkingPrice"
            value={formData.parkingPrice}
            onChange={handleChange}
            disabled={formData.parking.includes("gratuito")}
          />
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="custodito"
              name="custodito"
              checked={formData.parking.includes("custodito")}
              onChange={handleParkingChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />

            <label htmlFor="custodito" style={{ fontSize: fontScale(19) }}>
              Custodito
            </label>
          </div>
          <Spacer height={10} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="gratuito"
              name="gratuito"
              checked={formData.parking.includes("gratuito")}
              onChange={handleParkingChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />
            <label htmlFor="gratuito" style={{ fontSize: fontScale(19) }}>
              Gratuito
            </label>
          </div>
          <Spacer height={10} />
          <Input
            label=""
            placeholder="Inserisci l'indirizzo del parcheggio"
            type="address"
            name="parkingAddress"
            value={formData.parkingAddress}
            onChange={handleChange}
          />

          <Spacer height={20} />
          <Label text="Descrizione dell'evento" />
          <textarea
            name="description"
            placeholder='"Inserisci la descrizione"'
            value={formData.description}
            onChange={handleChange}
            style={{
              color: formData.description ? "#222" : "#D0D0D0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              opacity: 1,
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              height: scaleWidth(151),
              width: "90%",
              borderStyle: "solid", // Ensure the border style is solid
              fontFamily: "Lato",
            }}
          />
          <Spacer height={40} />

          <Label text="Seleziona Categoria (max.2)" />
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: scaleWidth(10) }}
          >
            {Object.keys(categories).map((category) => (
              <div
                key={category}
                onClick={() => handleCategoryClick(category)}
                style={{
                  borderRadius: scaleWidth(12),
                  backgroundColor: "#fff",
                  border: formData.categories.includes(category)
                    ? "2px solid #4bc592"
                    : "0px solid #ddd",
                  color: formData.categories.includes(category)
                    ? "#4bc592"
                    : "#222",
                  fontSize: fontScale(13),
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: scaleWidth(95),
                  height: scaleWidth(95),
                  textAlign: "center",
                  fontWeight: "700",
                  boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                {category}
              </div>
            ))}
          </div>
          <Spacer height={20} />

          {formData.categories.length > 0 && (
            <>
              <Label text="Seleziona Tag" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(8),
                }}
              >
                {formData.categories.flatMap((category) =>
                  categories[category].map((tag) => (
                    <div
                      key={tag}
                      onClick={() => handleTagClick(tag)}
                      style={{
                        height: scaleWidth(42),
                        paddingLeft: scaleWidth(20),
                        paddingRight: scaleWidth(20),
                        borderRadius: scaleWidth(25),
                        backgroundColor: "#fff",
                        color: formData.tags.includes(tag)
                          ? "#4bc592"
                          : "#b2b2b2",
                        fontSize: fontScale(18),
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        border: formData.tags.includes(tag)
                          ? "1.5px solid #4bc592"
                          : "1.5px solid #e8e8e8",
                      }}
                    >
                      {tag}
                    </div>
                  ))
                )}
              </div>
            </>
          )}

          {formData.tags.length > 0 && (
            <>
              <Spacer height={30} />
              <Label text="Tags Selezionati" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(10),
                }}
              >
                {formData.tags.map((tag) => (
                  <div
                    key={tag}
                    onClick={() => handleTagClick(tag)}
                    style={{
                      padding: scaleWidth(10),
                      borderRadius: scaleWidth(29),
                      backgroundColor: "#fff",
                      color: "#4bc592",
                      fontSize: fontScale(16),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      border: "0.5px solid #4bc592",
                      cursor: "pointer",
                    }}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </>
          )}

          <Spacer height={20} />
          <div style={{ display: "flex" }}>
            {/* <Button type="info" onClick={() => navigate(-1)}>
              Indietro
            </Button>
            <Spacer width={10} /> */}
            <Button>Modifica Evento</Button>
          </div>
          <Spacer height={30} />
        </form>
      </div>
    </div>
  );
};

export default EditEventScreen;
