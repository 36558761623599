import authService from "./authService"; // Import your authService
import axios from "axios";

const BASE_URL =
  //"http://localhost:3001/api";
  process.env.REACT_APP_BASE_URL || "https://hosts.eventoapp.it:3001/api";

/**
 * Generic function to handle API requests
 * @param {string} endpoint - The endpoint to make the request to.
 * @param {string} method - The HTTP method to use.
 * @param {object} [data] - The data to send with the request (for POST, PUT, PATCH).
 * @param {object} [params] - The query parameters to send with the request.
 * @returns {Promise} - The axios request promise.
 */
const apiRequest = async (endpoint, method, data = {}, params = null) => {
  try {
    // Get the ID token from the authService
    const idToken = await authService.getIdToken();

    // Proceed with the API request
    return axios({
      method: method,
      url: `${BASE_URL}${endpoint}`,
      data: data,
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`, // Include the Bearer token in the headers
      },
    });
  } catch (error) {
    console.error("Error in API request:", error);
    throw error;
  }
};

/**
 * Function to get all items
 * @param {string} itemType - The type of item to fetch.
 * @returns {Promise}
 */
export const getAllItems = (itemType) => {
  return apiRequest(`/${itemType}`, "GET");
};

/**
 * Function to get a single item by ID
 * @param {string} itemType - The type of item to fetch.
 * @param {string} itemId - The ID of the item to fetch.
 * @returns {Promise}
 */
export const getItemById = (itemType, itemId) => {
  return apiRequest(`/${itemType}/${itemId}`, "GET");
};

/**
 * Function to create a new item
 * @param {string} itemType - The type of item to create.
 * @param {object} itemData - The data of the item to create.
 * @returns {Promise}
 */
export const createItem = (itemType, itemData) => {
  return apiRequest(`/${itemType}`, "POST", itemData);
};

/**
 * Function to update an existing item
 * @param {string} itemType - The type of item to update.
 * @param {string} itemId - The ID of the item to update.
 * @param {object} itemData - The data to update the item with.
 * @returns {Promise}
 */
export const updateItem = (itemType, itemId, itemData) => {
  return apiRequest(`/${itemType}/${itemId}`, "PUT", itemData);
};

/**
 * Function to delete an item
 * @param {string} itemType - The type of item to delete.
 * @param {string} itemId - The ID of the item to delete.
 * @returns {Promise}
 */
export const deleteItem = (itemType, itemId) => {
  return apiRequest(`/${itemType}/${itemId}`, "DELETE");
};

/**
 * Function to filter items based on an attribute
 * @param {string} itemType - The type of item to filter.
 * @param {object} filterParams - The parameters to filter items by.
 * @returns {Promise}
 */
export const filterItems = (itemType, filterParams) => {
  return apiRequest(`/${itemType}/filter`, "GET", null, filterParams);
};
