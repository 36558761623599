import React, { useEffect, useState } from "react";
import {
  filterItems,
  getAllItems,
  updateItem,
} from "../../../services/apiService";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import authService from "../../../services/authService";
import { scaleWidth } from "../../../utils/responsive";
import { useNavigate } from "react-router-dom";
import verifiedIcon from "../../../assets/icons/verified.png";

// Added updateItem

const ProfileTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs">
      <button
        className={activeTab === "ongoing" ? "tab active" : "tab"}
        onClick={() => setActiveTab("ongoing")}
      >
        Dati dell'host
      </button>
      <button
        className={activeTab === "past" ? "tab active" : "tab"}
        onClick={() => setActiveTab("past")}
      >
        Statistiche
      </button>
    </div>
  );
};

const ProfileCard = ({ locals, events, host }) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div className="badge">
          <img src={verifiedIcon} alt="Verified" />
        </div>
        <div className="card-info">
          <div className="card-title">{host.name ?? ""}</div>
          <div className="card-subtitle">
            {host.role === "owner" ? (
              <>
                <strong style={{ color: "#000" }}>{locals}</strong> Locali |{" "}
              </>
            ) : (
              ""
            )}
            <strong style={{ color: "#000" }}>{events}</strong> Eventi
          </div>
        </div>
      </div>
      <Spacer height={20} />
    </div>
  );
};

const ProfileScreen = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("ongoing");
  const [locals, setLocals] = useState(0);
  const [events, setEvents] = useState(0);
  const [host, setHost] = useState({});
  const [formValues, setFormValues] = useState({
    companyName: "",
    vatNumber: "",
    adminName: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authService.getCurrentUser();

        const localsResponse = await getAllItems("locals/owned");
        setLocals(localsResponse.data.length);

        const eventsResponse = await getAllItems("events/owned");
        setEvents(eventsResponse.data.length);

        const hostResponse = await filterItems("hosts", {
          attribute: "firebaseUid",
          value: user.uid,
        });
        const u = hostResponse.data[0];
        console.log("Host data:", u);
        setHost(u);
        setFormValues({
          companyName: u.name || "",
          vatNumber: u.vat || "",
          adminName: u.administrator || "",
          phoneNumber: u.phone || "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedHostData = {
      name: formValues.companyName,
      vat: formValues.vatNumber,
      administrator: formValues.adminName,
      phone: formValues.phoneNumber,
    };

    try {
      await updateItem(`hosts`, host.id, updatedHostData); // Updating the host information
      // You can add navigation or success message logic here
      console.log("Host updated successfully");
    } catch (error) {
      console.error("Error updating host:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await authService.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div>
      <Header title={"Profilo Host"} hasBackButton={true} />
      <Spacer height={20} />
      <ProfileCard locals={locals} events={events} host={host ?? {}} />

      <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Spacer height={20} />

      {activeTab === "ongoing" ? (
        <div>
          <div
            style={{
              paddingRight: scaleWidth(40),
              paddingLeft: scaleWidth(40),
              paddingTop: scaleWidth(20),
            }}
          >
            <form onSubmit={handleSubmit}>
              <Input
                label={"Ragione sociale*"}
                placeholder='Es. "Mario Rossi SPA"'
                type="text"
                value={formValues.companyName}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
              />
              <Spacer height={30} />
              <Input
                label={"P.IVA*"}
                placeholder="Inserisci P.IVA"
                type="text"
                value={formValues.vatNumber}
                onChange={(e) => handleInputChange("vatNumber", e.target.value)}
              />
              <Spacer height={30} />
              <Input
                label={"Nome Amministratore"}
                placeholder="Nome e Cognome"
                type="text"
                value={formValues.adminName}
                onChange={(e) => handleInputChange("adminName", e.target.value)}
              />
              <Spacer height={30} />
              <Input
                label={"Numero di telefono*"}
                placeholder="Inserisci il numero di telefono"
                type="tel"
                value={formValues.phoneNumber}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
              <Spacer height={30} />
              <Button type="submit">Modifica i dati dell'host</Button>
            </form>
            <Spacer height={50} />
            <Button type="info" onClick={handleLogout}>
              Logout
            </Button>
            <Spacer height={50} />
            <p>v0.1.2</p>
            <Spacer height={100} />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>Non ci sono statistiche al momento</p>
        </div>
      )}
    </div>
  );
};

export default ProfileScreen;
