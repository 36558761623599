import PropTypes from "prop-types";
import React from "react";
import colors from "../assets/styles/colors";
import { fontScale } from "../utils/responsive";

const Button = ({ children, type, onClick, disabled = false }) => {
  const style = {
    background:
      type === "action"
        ? "#4BC592"
        : type === "info" || type === "back"
        ? colors.white
        : type === "success"
        ? "#4BC592"
        : "#222",
    color: type === "info" ? "#707070" : type === "back" ? "#222" : "#FFFFFF",
    textDecoration: type === "info" ? "underline" : "none",
    fontSize: fontScale(18),
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    opacity: disabled ? 0.6 : 1,
  };

  return (
    <button
      style={style}
      onClick={disabled ? null : onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "action",
    "info",
    "success",
    "danger",
    "submit",
    "back",
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
