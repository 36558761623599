import {
  createUserWithEmailAndPassword,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { auth } from "../utils/firebaseConfig";

const authService = {
  signUpWithEmail: async (email, password) => {
    console.log("Signing up with email and password");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      return { user, idToken };
    } catch (error) {
      throw error;
    }
  },

  signInWithEmail: async (email, password) => {
    console.log("Signing in with email and password");
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      return { user, idToken };
    } catch (error) {
      throw error;
    }
  },

  signOut: async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  },

  getIdToken: async () => {
    console.log("Getting ID token");
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        return idToken;
      }
      return null;
    } catch (error) {
      throw error;
    }
  },

  isLoggedIn: () => {
    return !!auth.currentUser;
  },

  onAuthStateChanged: (callback) => {
    return firebaseOnAuthStateChanged(auth, callback);
  },

  getCurrentUser: () => {
    const user = auth.currentUser;
    if (user) {
      return {
        ...user,
        role: authService.userRole, // Include the role in the returned user object
      };
    }
    return null;
  },
};

export default authService;
