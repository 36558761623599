import "./EventsScreen.css"; // Import your CSS for styling

import React, { useEffect, useState } from "react";

import { FaTimes } from "react-icons/fa";
import Spacer from "../../../components/Spacer";
import authService from "../../../services/authService";
import { filterItems } from "../../../services/apiService";
import { scaleWidth } from "../../../utils/responsive";
import { useNavigate } from "react-router-dom";

const EventsScreen = () => {
  const [activeTab, setActiveTab] = useState("ongoing");
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const user = authService.getCurrentUser();

        // const host = await filterItems("hosts", {
        //   attribute: "firebaseUid",
        //   value: user.uid,
        // });

        const eventsResponse = await filterItems("events", {
          attribute: "localId",
          value: "none",
        });

        console.log("Fetched events:", eventsResponse.data[0].host, user.uid);

        const ownedEvents = eventsResponse.data.filter(
          (event) => event.host === user.uid
        );
        setEvents(ownedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLocalData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("it-IT", {
      weekday: "short", // 'Sab'
      day: "numeric", // '25'
      month: "long", // 'ottobre'
      year: "numeric", // '2024'
    });
  };

  const EventBox = ({ event, onDelete }) => {
    const navigate = useNavigate();

    return (
      <div
        className="event-card"
        onClick={() =>
          navigate("/dashboard/event/edit", {
            state: { eventData: event }, // Pass the event data to the EditEventScreen
          })
        }
      >
        <div className="event-card-image">
          <img
            src={process.env.REACT_APP_BASE_MEDIA_URL + event.imgUrl}
            alt="Event"
          />
        </div>
        <div className="event-card-content">
          <h3>{event.name}</h3>
          <Spacer height={5} />
          <h3 style={{ fontWeight: 400 }}>{formatDate(event.date)}</h3>
          <h3 style={{ fontWeight: 400 }}>Ore: {event.startTime}</h3>
          <Spacer height={5} />
          <h3 style={{ fontWeight: 400 }}>
            Ingresso: {event.price === 0 ? "Gratuito" : "€" + event.price}
          </h3>
        </div>
        <div
          className="event-card-actions"
          onClick={(e) => e.stopPropagation()}
        >
          <FaTimes
            className="event-card-icon"
            onClick={() => onDelete(event._id)}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "ongoing":
        return (
          <div
            style={{
              paddingLeft: scaleWidth(20),
              paddingRight: scaleWidth(20),
            }}
          >
            {events.length > 0 &&
              events.map((event) => (
                <div className="settings-item" key={event.id}>
                  <EventBox event={event} onDelete={() => {}} />
                </div>
              ))}
            <div
              className="placeholder-content"
              onClick={() => {
                navigate("/dashboard/event/add", {
                  state: { local: { id: "none" } },
                });
              }}
            >
              <span className="plus-icon">+</span>
              <p>Aggiungi un evento</p>
            </div>
          </div>
        );
      case "past":
        return <p>Non ci sono eventi passati.</p>;
      case "drafts":
        return <p>Non ci sono bozze di eventi.</p>;
      default:
        return null;
    }
  };

  return (
    <div className="events-screen">
      <h1 className="header">I tuoi eventi</h1>
      <div className="tabs">
        <button
          className={activeTab === "ongoing" ? "tab active" : "tab"}
          onClick={() => setActiveTab("ongoing")}
        >
          In corso
        </button>
        <button
          className={activeTab === "past" ? "tab active" : "tab"}
          onClick={() => setActiveTab("past")}
        >
          Passati
        </button>
        <button
          className={activeTab === "drafts" ? "tab active" : "tab"}
          onClick={() => setActiveTab("drafts")}
        >
          Bozze
        </button>
      </div>
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default EventsScreen;
