import React from "react";
import PropTypes from "prop-types";
import { scaleWidth } from "../utils/responsive";

const Spacer = ({ height = 10, width = 10 }) => {
  const style = {
    height: scaleWidth(height),
    width: scaleWidth(width),
  };

  return <div style={style} />;
};

Spacer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Spacer;
