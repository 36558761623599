import { fontScale, scaleWidth } from "../utils/responsive"; // Assuming these utilities are available

import PropTypes from "prop-types";
import React from "react";

const Label = ({ text, style = {} }) => {
  const defaultStyles = {
    color: "#222",
    fontSize: fontScale(18),
    marginBottom: scaleWidth(5),
    marginLeft: scaleWidth(5),
  };

  return <label style={{ ...defaultStyles, ...style }}>{text}</label>;
};

Label.propTypes = {
  text: PropTypes.string.isRequired, // The text content of the label
  style: PropTypes.object, // Optional additional styles
};

export default Label;
