import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import { FaUpload } from "react-icons/fa6";
import Header from "../../../components/Header";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import axios from "axios";
import categories from "../../../utils/categories";
import { updateItem } from "../../../services/apiService";

const AddEventMoreScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventData = location.state?.eventData || {};

  const [formData, setFormData] = useState({
    ...eventData,
    imgUrl: eventData.imgUrl || "",
    description: eventData.description || "",
    category: "",
    categories: [], // New state for multiple categories
    tags: [],
  });

  const handleCategoryClick = (category) => {
    setFormData((prevState) => {
      const isSelected = prevState.categories.includes(category);
      const newCategories = isSelected
        ? prevState.categories.filter((cat) => cat !== category)
        : prevState.categories.length < 2
        ? [...prevState.categories, category]
        : prevState.categories;
      return { ...prevState, categories: newCategories };
    });
  };

  const handleTagClick = (tag) => {
    setFormData((prevState) => {
      const isSelected = prevState.tags.includes(tag);
      const newTags = isSelected
        ? prevState.tags.filter((t) => t !== tag) // Remove the tag if it's already selected
        : [...prevState.tags, tag]; // Add the tag if it's not selected
      return { ...prevState, tags: newTags };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "category") {
      setFormData({ ...formData, category: value, subcategory: "" });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        imgUrl: `/media/${response.data.filename}`, // Save the image URL
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set the `category` to the first element of `categories`
    setFormData((prevState) => ({
      ...prevState,
      category: prevState.categories[0] || "",
    }));

    updateItem("events", formData.id, formData)
      .then((response) => {
        console.log("Event updated successfully:", response.data);
        navigate(-2);
      })
      .catch((error) => {
        console.error("Error updating event:", error);
      });
  };

  return (
    <div>
      <Header title={"Inserisci evento 2/2"} />

      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          paddingTop: scaleWidth(20),
        }}
      >
        <h2>Aggiungi i dettagli finali</h2>

        <Spacer height={10} />
        <p style={{ fontSize: fontScale(20) }}>
          Condividere i dettagli sul proprietario del locale ci permette di
          garantire un'esperienza sicura e affidabile a tutti i nostri utenti.
        </p>
        <Spacer height={40} />

        <form onSubmit={handleSubmit}>
          <Spacer height={20} />
          <Label text="Locandina dell'evento" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                position: "relative",
                width: scaleWidth(165),
                height: scaleWidth(233),
                borderRadius: 10,
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundImage: formData.imgUrl
                  ? `url(${
                      process.env.REACT_APP_BASE_MEDIA_URL + formData.imgUrl
                    })`
                  : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              {!formData.imgUrl && <FaUpload size={50} color="#ccc" />}
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none",
                }}
              />
            </div>
            <div
              style={{
                width: scaleWidth(150),
              }}
            >
              <Spacer height={40} />

              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>Formato minimo A5: 420px x 595px</i>
              </p>

              <Spacer height={20} />
              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>Dimensione massima: 1 MB</i>
              </p>
            </div>
          </div>
          <Spacer height={20} />
          <Label text="Descrizione dell'evento" />
          <textarea
            name="description"
            placeholder='"Inserisci la descrizione"'
            value={formData.description}
            onChange={handleChange}
            style={{
              color: formData.description ? "#222" : "#D0D0D0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              opacity: 1,
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              height: scaleWidth(151),
              width: "90%",
              borderStyle: "solid", // Ensure the border style is solid
              fontFamily: "Lato",
            }}
          />
          <Spacer height={40} />

          <Label text="Seleziona Categoria (max.2)" />
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: scaleWidth(10) }}
          >
            {Object.keys(categories).map((category) => (
              <div
                key={category}
                onClick={() => handleCategoryClick(category)}
                style={{
                  borderRadius: scaleWidth(12),
                  backgroundColor: "#fff",
                  border: formData.categories.includes(category)
                    ? "2px solid #4bc592"
                    : "0px solid #ddd",
                  color: formData.categories.includes(category)
                    ? "#4bc592"
                    : "#222",
                  fontSize: fontScale(13),
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: scaleWidth(95),
                  height: scaleWidth(95),
                  textAlign: "center",
                  fontWeight: "700",
                  boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                {category}
              </div>
            ))}
          </div>
          <Spacer height={20} />

          {formData.categories.length > 0 && (
            <>
              <Label text="Seleziona Tag" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(8),
                }}
              >
                {formData.categories.flatMap((category) =>
                  categories[category].map((tag) => (
                    <div
                      key={tag}
                      onClick={() => handleTagClick(tag)}
                      style={{
                        height: scaleWidth(42),
                        paddingLeft: scaleWidth(20),
                        paddingRight: scaleWidth(20),
                        borderRadius: scaleWidth(25),
                        backgroundColor: "#fff",
                        color: formData.tags.includes(tag)
                          ? "#4bc592"
                          : "#b2b2b2",
                        fontSize: fontScale(18),
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        border: formData.tags.includes(tag)
                          ? "1.5px solid #4bc592"
                          : "1.5px solid #e8e8e8",
                      }}
                    >
                      {tag}
                    </div>
                  ))
                )}
              </div>
            </>
          )}

          {formData.tags.length > 0 && (
            <>
              <Spacer height={30} />
              <Label text="Tags Selezionati" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(10),
                }}
              >
                {formData.tags.map((tag) => (
                  <div
                    key={tag}
                    onClick={() => handleTagClick(tag)} // Call handleTagClick to remove the tag
                    style={{
                      padding: scaleWidth(10),
                      borderRadius: scaleWidth(29),
                      backgroundColor: "#fff",
                      color: "#4bc592",
                      fontSize: fontScale(16),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      border: "0.5px solid #4bc592",
                      cursor: "pointer", // Change cursor to pointer to indicate clickability
                    }}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </>
          )}

          <Spacer height={20} />

          <div
            style={{
              display: "flex",
              position: "fixed",
              height: scaleWidth(55),
              paddingLeft: scaleWidth(23),
              paddingTop: scaleWidth(12),
              paddingBottom: scaleWidth(13),
              paddingRight: scaleWidth(23),
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#fff",
              borderTop: "1px solid #e8e8e8",
            }}
          >
            <Button type="back" onClick={() => navigate(-1)}>
              Indietro
            </Button>
            <Spacer width={10} />
            <Button type="success">Prosegui</Button>
          </div>
          <Spacer height={100} />
        </form>
      </div>
    </div>
  );
};

export default AddEventMoreScreen;
