import { fontScale, scaleWidth } from "../utils/responsive";

import PropTypes from "prop-types";
import React from "react";
import backIcon from "../assets/icons/backIcon.png"; // Import the back icon image
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const Header = ({ title, hasBackButton = false }) => {
  // Default value for hasBackButton
  const navigate = useNavigate(); // Initialize the navigate function

  const styles = {
    headerContainer: {
      color: "#fff",
      backgroundColor: "#4bc592",
      textAlign: "center",
      position: "relative", // Relative position to allow absolute positioning of the back icon
      height: scaleWidth(74),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backIcon: {
      position: "absolute",
      left: scaleWidth(7),
      width: scaleWidth(42),
      height: scaleWidth(42),
      cursor: "pointer",
    },
    title: {
      margin: 0,
      fontSize: fontScale(30),
    },
  };

  return (
    <div style={styles.headerContainer}>
      {hasBackButton && (
        <img
          src={backIcon}
          alt="Back"
          style={styles.backIcon}
          onClick={() => navigate(-1)} // Trigger navigation on click
        />
      )}
      <h1 style={styles.title}>{title}</h1>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  hasBackButton: PropTypes.bool, // Define the hasBackButton prop type
};

export default Header;
