import "./Local.css"; // Import CSS for styling

import { FaArrowLeft, FaPlusCircle, FaTimes } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  deleteItem,
  filterItems,
  getItemById,
} from "../../../services/apiService";
import { useNavigate, useParams } from "react-router-dom";

import LocalInformation from "./LocalInformation";
import Spacer from "../../../components/Spacer";
import axios from "axios";
import infoIcon from "../../../assets/icons/info.png";
import partyIcon from "../../../assets/icons/party.png";
import { scaleWidth } from "../../../utils/responsive";
import { updateItem } from "../../../services/apiService";

const LocalHeader = ({ local, activeMainTab, setActiveMainTab, events }) => {
  const [logoUrl, setLogoUrl] = useState(local.logoUrl);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newLogoUrl = `/media/${response.data.filename}`;

      // Update the state with the new logo URL
      setLogoUrl(newLogoUrl);

      // Update the local in the database with the new logo URL
      await updateItem("locals", local.id, { logoUrl: newLogoUrl });
      console.log("Logo updated successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="local-header">
      <div className="local-header-info">
        <FaArrowLeft
          className="back-icon"
          onClick={() => window.history.back()}
        />
        <Spacer width={15} />

        <div
          className="logo-container"
          onClick={() => document.getElementById("logoUploadInput").click()}
        >
          <img
            src={process.env.REACT_APP_BASE_MEDIA_URL + logoUrl}
            alt="Logo"
            className="local-logo"
          />
          <FaPlusCircle className="add-icon" />
        </div>

        <input
          id="logoUploadInput"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />

        <div className="local-details">
          <h1 className="local-name">{local.name}</h1>
          <div className="local-stats">
            <span>{events.length ?? ""} Eventi</span>
            <span className="divider">|</span>
            <span>{0} Follower</span>
          </div>
        </div>
      </div>

      <div className="local-buttons">
        <div
          className={`button ${activeMainTab === "eventi" ? "active" : ""}`}
          // style={{
          //   justifyContent: "center",
          //   alignItems: "center",
          //   display: "flex",
          // }}
          onClick={() => setActiveMainTab("eventi")}
        >
          <img
            src={partyIcon}
            alt=""
            style={{
              height: scaleWidth(18),
              width: scaleWidth(18),
              marginRight: scaleWidth(5),
            }}
          />
          Eventi
        </div>
        <div
          className={`button ${
            activeMainTab === "informazioni" ? "active" : ""
          }`}
          onClick={() => setActiveMainTab("informazioni")}
        >
          <img
            src={infoIcon}
            alt=""
            style={{
              height: scaleWidth(18),
              width: scaleWidth(18),
              marginRight: scaleWidth(5),
            }}
          />
          Informazioni
        </div>
      </div>
    </div>
  );
};

const EventTabs = ({ activeEventTab, setActiveEventTab }) => {
  return (
    <div className="tabs">
      <button
        className={activeEventTab === "ongoing" ? "tab active" : "tab"}
        onClick={() => setActiveEventTab("ongoing")}
      >
        In corso
      </button>
      <button
        className={activeEventTab === "past" ? "tab active" : "tab"}
        onClick={() => setActiveEventTab("past")}
      >
        Passati
      </button>
      <button
        className={activeEventTab === "drafts" ? "tab active" : "tab"}
        onClick={() => setActiveEventTab("drafts")}
      >
        Bozze
      </button>
    </div>
  );
};

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("it-IT", {
    weekday: "short", // 'Sab'
    day: "numeric", // '25'
    month: "long", // 'ottobre'
    year: "numeric", // '2024'
  });
};

const EventBox = ({ event, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div
      className="event-card"
      onClick={() =>
        navigate("/dashboard/event/edit", {
          state: { eventData: event }, // Pass the event data to the EditEventScreen
        })
      }
    >
      <div className="event-card-image">
        <img
          src={process.env.REACT_APP_BASE_MEDIA_URL + event.imgUrl}
          alt="Event"
        />
      </div>
      <div className="event-card-content">
        <h3>{event.name}</h3>
        <Spacer height={5} />
        <h3 style={{ fontWeight: 400 }}>{formatDate(event.date)}</h3>
        <h3 style={{ fontWeight: 400 }}>Ore: {event.startTime}</h3>
        <Spacer height={5} />
        <h3 style={{ fontWeight: 400 }}>
          Ingresso: {event.price === 0 ? "Gratuito" : "€" + event.price}
        </h3>
      </div>
      <div className="event-card-actions" onClick={(e) => e.stopPropagation()}>
        <FaTimes
          className="event-card-icon"
          onClick={() => onDelete(event.id)}
        />
      </div>
    </div>
  );
};

const EventsCard = ({
  events,
  local,
  onDelete,
  activeEventTab,
  setActiveEventTab,
}) => {
  const navigate = useNavigate();

  // Filter events into ongoing/future and past
  const now = new Date();
  const ongoingEvents = events.filter((event) => new Date(event.date) >= now);
  const pastEvents = events.filter((event) => new Date(event.date) < now);

  return (
    <div>
      <EventTabs
        activeEventTab={activeEventTab}
        setActiveEventTab={setActiveEventTab}
      />

      <div className="settings-section">
        {activeEventTab === "ongoing" &&
          (ongoingEvents.length > 0 ? (
            ongoingEvents.map((event) => (
              <div className="settings-item" key={event.id}>
                <EventBox event={event} onDelete={onDelete} />
              </div>
            ))
          ) : (
            <p>Nessun evento in corso</p>
          ))}

        {activeEventTab === "past" &&
          (pastEvents.length > 0 ? (
            pastEvents.map((event) => (
              <div className="settings-item" key={event.id}>
                <EventBox event={event} onDelete={onDelete} />
              </div>
            ))
          ) : (
            <p>Nessun evento passato</p>
          ))}

        {activeEventTab === "drafts" && <p>Nulla qui</p>}

        <div
          className="placeholder-content"
          onClick={() => {
            navigate("/dashboard/event/add", {
              state: { local },
            });
          }}
        >
          <span className="plus-icon">+</span>
          <p>Aggiungi un evento</p>
        </div>
      </div>
    </div>
  );
};

const LocalScreen = () => {
  const { id } = useParams();
  const [local, setLocal] = useState(null);
  const [events, setEvents] = useState([]);
  const [activeMainTab, setActiveMainTab] = useState("eventi");
  const [activeEventTab, setActiveEventTab] = useState("ongoing");

  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const localResponse = await getItemById("locals", id);
        setLocal(localResponse.data);
        const eventsResponse = await filterItems("events", {
          attribute: "localId",
          value: id,
        });
        setEvents(eventsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLocalData();
  }, [id]);

  const handleDelete = async (eventId) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo evento? Questa azione non può essere annullata."
    );

    if (confirmDelete) {
      try {
        await deleteItem("events", eventId);
        setEvents(events.filter((e) => e.id !== eventId));
        console.log("Event deleted successfully");
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    } else {
      console.log("Eliminazione annullata");
    }
  };

  if (!local) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <LocalHeader
        local={local}
        activeMainTab={activeMainTab}
        setActiveMainTab={setActiveMainTab}
        events={events}
      />
      {activeMainTab === "eventi" ? (
        <EventsCard
          events={events}
          local={local}
          onDelete={handleDelete}
          activeEventTab={activeEventTab}
          setActiveEventTab={setActiveEventTab} // Pass the setter function here
        />
      ) : (
        <LocalInformation local={local} />
      )}
    </div>
  );
};

export default LocalScreen;
