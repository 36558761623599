import { fontScale, scaleWidth } from "../utils/responsive";

import React from "react";
import Spacer from "./Spacer";

const CustomRadioButton = ({ id, name, value, checked, onChange, label }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label
        htmlFor={id}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          style={{
            position: "fixed",
            opacity: 0,
            cursor: "pointer",
          }}
        />
        <span
          style={{
            width: scaleWidth(14),
            height: scaleWidth(14),
            backgroundColor: checked ? "#4bc592" : "#e7e7e7",
            borderRadius: scaleWidth(18),
            border: `5px solid #fff`,
            boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        />
        <Spacer width={17} />
        <span style={{ fontSize: fontScale(17), fontWeight: "400" }}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default CustomRadioButton;
