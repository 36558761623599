import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import { FaArrowLeft } from "react-icons/fa";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import { updateItem } from "../../../services/apiService"; // Adjust the import according to your service

const EditEventScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventData = location.state?.eventData || {};
  const local = eventData.local || {};

  console.log("Event data:", eventData.host || eventData.hostId);

  const [formData, setFormData] = useState({
    id: eventData.id || "",
    name: eventData.name || "",
    localId: local.id || "",
    address: eventData.address || "",
    date: eventData.date ? eventData.date.split("T")[0] : "", // Extract the date part
    endDate: eventData.endDate ? eventData.endDate.split("T")[0] : "",
    startTime: eventData.startTime || "",
    endTime: eventData.endTime || "",
    price: eventData.price || "",
    parkingPrice: eventData.parkingPrice || "",
    category: eventData.category || "",
    subcategory: eventData.subcategory || "",
    description: eventData.description || "",
    imgUrl: eventData.imgUrl || "",
    parking: eventData.parking || [],
    isParkingSecure: eventData.isParkingSecure || false,
    parkingAddress: eventData.parkingAddress || "",
    hasEndDate: eventData.endDate && eventData.endDate !== "" ? false : true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleParkingChange = (e) => {
    const { name, checked } = e.target;
    let updatedParking = [...formData.parking];

    if (checked) {
      updatedParking.push(name);
    } else {
      updatedParking = updatedParking.filter((item) => item !== name);
    }

    setFormData({
      ...formData,
      parking: updatedParking,
      parkingPrice: updatedParking.includes("gratuito")
        ? 0
        : formData.parkingPrice,
      isParkingSecure: updatedParking.includes("custodito"),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEventData = {
      name: formData.name,
      hostId: formData.host,
      localId: local.id,
      address: formData.address,
      date: formData.date,
      endDate: formData.hasEndDate ? formData.endDate : null,
      startTime: formData.startTime,
      endTime: formData.endTime,
      price: parseFloat(formData.price),
      parkingPrice: parseFloat(formData.parkingPrice),
      category: formData.category,
      subcategory: formData.subcategory,
      description: formData.description,
      imgUrl: formData.imgUrl,
      parking: formData.parking,
      isParkingSecure: formData.isParkingSecure,
    };

    console.log("Updating event:", formData);

    updateItem(`events/`, formData.id, updatedEventData)
      .then((response) => {
        console.log("Event updated successfully:", response.data);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error updating event:", error);
      });
  };

  return (
    <div>
      <div style={{ textAlign: "center", backgroundColor: "#f7f7f7" }}>
        <FaArrowLeft
          style={{
            position: "absolute",
            top: scaleWidth(28),
            left: scaleWidth(10),
            cursor: "pointer",
            color: "black",
            fontSize: fontScale(20),
          }}
          onClick={() => navigate(-1)}
        />
        <Spacer height={20} />
        <img
          style={{ width: scaleWidth(143), height: "auto", borderRadius: 10 }}
          src={process.env.REACT_APP_BASE_MEDIA_URL + formData.imgUrl}
          alt="Event"
        />
        <Spacer height={20} />
      </div>
      <h1
        style={{
          backgroundColor: "#fff",
          color: "black",
          textAlign: "center",
          padding: scaleWidth(15),
          backgroundSize: "cover",
          borderBottom: "3px solid #4BC592", // Added bottom green border
        }}
      >
        {formData.name}
      </h1>

      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          paddingTop: scaleWidth(30),
        }}
      >
        <form onSubmit={handleSubmit}>
          <Input
            label="Titolo dell'evento"
            placeholder="Inserisci titolo"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <Spacer height={20} />

          <Input
            label="Luogo dell'evento"
            placeholder="Inserisci il luogo dell'evento"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />

          <Spacer height={20} />
          <Input
            label="Giorno/i di svolgimento"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="hasEndDate"
              name="hasEndDate"
              checked={formData.hasEndDate}
              onChange={handleChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />
            <label htmlFor="hasEndDate" style={{ fontSize: fontScale(17) }}>
              L'evento si conclude il giorno stesso
            </label>
          </div>
          {!formData.hasEndDate && (
            <>
              <Spacer height={20} />
              <Input
                label="Data di fine"
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </>
          )}
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              label="Ora di inizio"
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
            />
            <Spacer width={20} />
            <Input
              label="Ora di fine"
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
            />
          </div>
          <Spacer height={20} />
          <Input
            label="Prezzo"
            placeholder="Inserisci il prezzo dell'evento"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
          <Spacer height={30} />

          <label
            style={{
              color: "#222",
              fontSize: fontScale(18),
              marginBottom: scaleWidth(5),
              marginLeft: scaleWidth(5),
            }}
          >
            Indicazioni parcheggio (se presente)
          </label>
          <Spacer height={10} />
          <Input
            label=""
            placeholder="Inserisci il prezzo del parcheggio"
            type="number"
            name="parkingPrice"
            value={formData.parkingPrice}
            onChange={handleChange}
            disabled={formData.parking.includes("gratuito")}
          />
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="custodito"
              name="custodito"
              checked={formData.parking.includes("custodito")}
              onChange={handleParkingChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />

            <label htmlFor="custodito" style={{ fontSize: fontScale(19) }}>
              Custodito
            </label>
          </div>
          <Spacer height={10} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="gratuito"
              name="gratuito"
              checked={formData.parking.includes("gratuito")}
              onChange={handleParkingChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />
            <label htmlFor="gratuito" style={{ fontSize: fontScale(19) }}>
              Gratuito
            </label>
          </div>
          <Spacer height={10} />
          <Input
            label=""
            placeholder="Inserisci l'indirizzo del parcheggio"
            type="address"
            name="parkingAddress"
            value={formData.parkingAddress}
            onChange={handleChange}
            disabled={formData.parking.includes("gratuito")}
          />

          <Spacer height={20} />
          <div style={{ display: "flex" }}>
            {/* <Button type="info" onClick={() => navigate(-1)}>
              Indietro
            </Button>
            <Spacer width={10} /> */}
            <Button>Modifica Evento</Button>
          </div>
          <Spacer height={30} />
        </form>
      </div>
    </div>
  );
};

export default EditEventScreen;
