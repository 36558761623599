// responsive.js

// Get screen dimensions from the window object
const SCREEN_WIDTH = window.innerWidth;
const SCREEN_HEIGHT = window.innerHeight;

// Reference design dimensions from your Figma design
const FIGMA_WIDTH = 390; // Figma design width
const FIGMA_HEIGHT = 865; // Figma design height

// Define a breakpoint for switching between mobile and desktop scaling
const MOBILE_BREAKPOINT = 768; // Example breakpoint for mobile vs desktop

// Scale functions with conditional logic for mobile vs desktop
export const scaleWidth = (size) => {
  if (SCREEN_WIDTH <= MOBILE_BREAKPOINT) {
    return (SCREEN_WIDTH / FIGMA_WIDTH) * size; // Mobile scaling
  } else {
    return size; // No scaling on desktop (or you can apply a different scaling factor)
  }
};

// TODO: scale via width, but use it for heights
export const scaleHeight = (size) => {
  if (SCREEN_HEIGHT <= MOBILE_BREAKPOINT) {
    return (SCREEN_HEIGHT / FIGMA_HEIGHT) * size; // Mobile scaling
  } else {
    return size; // No scaling on desktop (or you can apply a different scaling factor)
  }
};

export const fontScale = (size) => scaleWidth(size);

const responsiveUtils = {
  scaleWidth,
  scaleHeight,
  fontScale,
};

export default responsiveUtils;
