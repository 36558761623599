import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import React from "react";
import Spacer from "../../../components/Spacer";
import localImage from "../../../assets/imgs/local.png";
import { scaleWidth } from "../../../utils/responsive";

const AddedLocalScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const localName = location.state?.localName || "il tuo locale";

  return (
    <div
      style={{
        textAlign: "center",
        paddingRight: scaleWidth(40),
        paddingLeft: scaleWidth(40),
        paddingTop: scaleWidth(60),
      }}
    >
      <img style={{ width: scaleWidth(212) }} src={localImage} alt="Locale" />

      <Spacer height={20} />
      <h1
        style={{
          color: "#222",
          fontSize: scaleWidth(28),
        }}
      >
        Hai aggiunto <i>{localName}</i> all'elenco dei tuoi locali
      </h1>
      <Spacer height={100} />

      <Button onClick={() => navigate("/dashboard/locals")}>
        Visualizza elenco locali
      </Button>
      <Spacer height={20} />

      {/* <Button type="success" onClick={() => navigate("/dashboard/add/event")}>
        CREA UN EVENTO
      </Button> */}

      <Spacer height={100} />
    </div>
  );
};

export default AddedLocalScreen;
