import { Routes, Route } from "react-router-dom";
import WelcomeScreen from "../screens/Auth/WelcomeScreen";
import TypeScreen from "../screens/Auth/TypeScreen";
import SignupScreen from "../screens/Auth/SignupScreen";
import SignupDetailsScreen from "../screens/Auth/SignupDetailsScreen";
import CreatedScreen from "../screens/Auth/CreatedScreen";
import LoginScreen from "../screens/Auth/LoginScreen";

function AuthNavigator() {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/type" element={<TypeScreen />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/details" element={<SignupDetailsScreen />} />
      <Route path="/created" element={<CreatedScreen />} />
      <Route path="/login" element={<LoginScreen />} />
    </Routes>
  );
}

export default AuthNavigator;
