import "./TicketScreen.css"; // Import your CSS for styling

import React, { useEffect, useState } from "react";

// import { useNavigate } from "react-router-dom";
// import { getAllItems } from "../../../services/apiService";
import { FaEllipsisVertical } from "react-icons/fa6";
import Header from "../../../components/Header";
import Spacer from "../../../components/Spacer";

const TicketsTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs">
      <button
        className={activeTab === "ongoing" ? "tab active" : "tab"}
        onClick={() => setActiveTab("ongoing")}
      >
        In corso
      </button>
      <button
        className={activeTab === "past" ? "tab active" : "tab"}
        onClick={() => setActiveTab("past")}
      >
        Passati
      </button>
      <button
        className={activeTab === "draft" ? "tab active" : "tab"}
        onClick={() => setActiveTab("draft")}
      >
        Bozze
      </button>
    </div>
  );
};

const TicketScreen = () => {
  // const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState("ongoing");

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // const response = await getAllItems("tickets");
        // console.log("Fetched tickets:", response.data);
        setNotifications([]);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchNotifications();
  }, []);

  // const handleAddTicketClick = () => {
  //   // navigate("/dashboard/local/add");
  // };

  return (
    <div className="notifications-screen">
      <Header title={"Gestione biglietti"} />
      <TicketsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="notifications-list">
        <Spacer height={20} />

        {notifications.length > 0 ? (
          notifications.map((local) => (
            <div key={local.id} className="local-card">
              <img
                src={local.logoUrl}
                alt={"Logo " + local.name}
                className="local-logo"
              />
              <div className="local-info">
                <h2 className="local-name">{local.name}</h2>
                <p className="local-category">{local.address}</p>
              </div>
              <div className="local-options">
                <FaEllipsisVertical />
              </div>
            </div>
          ))
        ) : (
          <div>
            <p>Genera un biglietto</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketScreen;
