import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import { updateItem } from "../../../services/apiService"; // Assuming this is the function to update the local

// import { useNavigate } from "react-router-dom";

const LocalInformation = ({ local }) => {
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: local.name || "",
    address: local.address || "",
    description: local.description || "",
    website: local.website || "",
    facebook: local.socials?.facebook || "",
    instagram: local.socials?.instagram || "",
    tiktok: local.socials?.tiktok || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedLocalData = {
      name: formData.name,
      address: formData.address,
      description: formData.description,
      website: formData.website,
      socials: {
        facebook: formData.facebook,
        instagram: formData.instagram,
        tiktok: formData.tiktok,
      },
    };

    try {
      await updateItem(`locals/`, local.id, updatedLocalData);
      // navigate("/dashboard/local/view", {
      //   state: { localData: updatedLocalData },
      // });
    } catch (error) {
      console.error("Error updating local:", error);
    }
  };

  const InfoTabs = () => {
    const [activeTab, setActiveTab] = useState("local");

    return (
      <div className="tabs">
        <button
          className={activeTab === "local" ? "tab active" : "tab"}
          onClick={() => setActiveTab("local")}
        >
          Locale
        </button>
        <button
          className={activeTab === "stats" ? "tab active" : "tab"}
          onClick={() => setActiveTab("stats")}
        >
          Statistiche
        </button>
      </div>
    );
  };

  return (
    <div>
      <InfoTabs />

      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          paddingTop: scaleWidth(20),
        }}
      >
        <form onSubmit={handleSubmit}>
          <Input
            label="Nome del locale"
            placeholder="Inserisci il nome del locale"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Indirizzo"
            placeholder="Inserisci l'indirizzo"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Label text={"Descrizione del locale"} />
          <textarea
            placeholder="Inserisci la descrizione"
            name="description"
            value={formData.description}
            onChange={handleChange}
            style={{
              color: formData.description ? "#222" : "#D0D0D0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              opacity: 1,
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              height: scaleWidth(151),
              width: "90%",
              borderStyle: "solid", // Ensure the border style is solid
              fontFamily: "Lato",
            }}
          />
          <Spacer height={20} />
          <Input
            label="Sito Web"
            placeholder="Inserisci il sito web"
            type="url"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Facebook"
            placeholder="Inserisci il link di Facebook"
            type="url"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Instagram"
            placeholder="Inserisci il link di Instagram"
            type="url"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="TikTok"
            placeholder="Inserisci il link di TikTok"
            type="url"
            name="tiktok"
            value={formData.tiktok}
            onChange={handleChange}
          />
          <Spacer height={30} />
          <div style={{ display: "flex" }}>
            <Button>Modifica i dati del locale</Button>
          </div>
          <Spacer height={30} />
        </form>
      </div>
    </div>
  );
};

export default LocalInformation;
