import React from "react";
import { useNavigate } from "react-router-dom";
import createdImage from "../../assets/imgs/created.png";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import { fontScale, scaleWidth } from "../../utils/responsive";

const CreatedScreen = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        paddingRight: scaleWidth(32),
        paddingLeft: scaleWidth(32),
      }}
    >
      <Spacer height={20} />
      <h1
        style={{
          color: "#4BC592",
          textAlign: "center",
          fontSize: scaleWidth(29),
        }}
      >
        Il tuo account è stato registrato con successo!
      </h1>
      <Spacer height={20} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: scaleWidth(275) }} src={createdImage} alt="User" />
      </div>
      <Spacer height={20} />

      <h2 style={{ color: "#222", fontSize: fontScale(27) }}>
        Ora puoi iniziare a pubblicare i tuoi eventi
      </h2>
      <Spacer height={10} />
      <p style={{ color: "#222", fontSize: fontScale(20) }}>
        Se possiedi un locale o un'attività, l'Account Host ti offre il modo
        perfetto per promuoverlo come sede dei tuoi eventi. Fai brillare i tuoi
        spazi e attira una folla entusiasta!
      </p>
      <Spacer height={50} />

      <Button type="action" onClick={() => navigate("/login")}>
        PUBBLICA I TUOI EVENTI
      </Button>
    </div>
  );
};

export default CreatedScreen;
