import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import { FaUpload } from "react-icons/fa";
import Header from "../../../components/Header";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import axios from "axios"; // Import axios for making HTTP requests
import { createItem } from "../../../services/apiService";

const AddLocalDetailsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = location.state || {};
  const [charCount, setCharCount] = useState(0);

  const [formData, setFormData] = useState({
    ...initialFormData,
    logoUrl: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setCharCount(value.length);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        logoUrl: `/media/${response.data.filename}`, // Save the image URL
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createItem("locals", formData)
      .then((response) => {
        console.log("Locale created successfully:", response.data);
        navigate("/dashboard/local/success", {
          state: { localName: formData.name },
        });
      })
      .catch((error) => {
        console.error("Error creating locale:", error);
      });
  };

  return (
    <div>
      <Header title={"Aggiungi locale"} hasBackButton={true} />

      <div
        style={{
          paddingRight: scaleWidth(30),
          paddingLeft: scaleWidth(35),
          paddingTop: scaleWidth(31),
          paddingBottom: scaleWidth(10),
        }}
      >
        <h2
          style={{
            fontSize: fontScale(28),
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Completa le ultime informazioni necessarie
        </h2>
        <Spacer height={30} />

        <form onSubmit={handleSubmit}>
          <Label text="Descrizione/Biografia del locale" />
          <div>
            <textarea
              name="description"
              placeholder='"Benvenuti..."'
              value={formData.description}
              onChange={handleChange}
              maxLength={500}
              style={{
                color: formData.description ? "#222" : "#D0D0D0",
                fontSize: fontScale(20),
                borderColor: "#D0D0D0",
                opacity: 1,
                borderWidth: 0.5,
                borderRadius: 6,
                padding: scaleWidth(15),
                height: scaleWidth(151),
                width: "90%",
                borderStyle: "solid",
                fontFamily: "Lato",
              }}
            />
            <div
              style={{
                textAlign: "right",
                fontSize: fontScale(16),
                color: "#d0d0d0",
              }}
            >
              {charCount}/500
            </div>
          </div>
          <Spacer height={30} />
          <Label text="Logo/Marchio" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                position: "relative",
                width: scaleWidth(120),
                height: scaleWidth(120),
                borderRadius: 10,
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundImage: formData.logoUrl
                  ? `url(${
                      process.env.REACT_APP_BASE_MEDIA_URL + formData.logoUrl
                    })`
                  : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              {!formData.logoUrl && <FaUpload size={24} color="#d0d0d0" />}
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none",
                }}
              />
            </div>
            <div
              style={{
                width: scaleWidth(150),
              }}
            >
              <Spacer height={12} />

              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>
                  Formato minimo:
                  <br /> 500px x 500px
                </i>
              </p>

              <Spacer height={20} />
              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>Dimensione massima: 1 MB</i>
              </p>
            </div>
          </div>

          <Spacer height={90} />
          <div
            style={{
              display: "flex",
              position: "fixed",
              height: scaleWidth(55),
              paddingLeft: scaleWidth(23),
              paddingTop: scaleWidth(12),
              paddingBottom: scaleWidth(13),
              paddingRight: scaleWidth(23),
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#fff",
              borderTop: "1px solid #e8e8e8",
            }}
          >
            <Button type="back" onClick={() => navigate(-1)}>
              Indietro
            </Button>
            <Spacer width={30} />
            <Button type="success" onClick={handleSubmit}>
              Prosegui
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLocalDetailsScreen;
