import { fontScale, scaleWidth } from "../utils/responsive";

import React from "react";

const PlaceholderContent = ({ onClick, imageSrc, text }) => {
  const styles = {
    placeholderContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "75vh",
      color: "#4bc592",
      cursor: "pointer",
    },
    placeholderText: {
      marginTop: "10px",
      fontSize: fontScale(20),
      color: "#222",
    },
    plusIconBig: {
      fontSize: fontScale(35),
      height: scaleWidth(40),
      width: scaleWidth(40),
      borderRadius: scaleWidth(40),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      backgroundColor: "#4bc592",
      zIndex: 2,
    },
    noLocalImg: {
      width: scaleWidth(227),
      height: scaleWidth(227),
      transform: "translateY(20px)",
      zIndex: 1,
    },
  };

  return (
    <div style={styles.placeholderContent} onClick={onClick}>
      <img style={styles.noLocalImg} src={imageSrc} alt={text} />
      <span style={styles.plusIconBig}>+</span>
      <p style={styles.placeholderText}>{text}</p>
    </div>
  );
};

export default PlaceholderContent;
