import { Route, Routes, useLocation } from "react-router-dom";

import AddEventMoreScreen from "../screens/Dashboard/Events/AddEventMore";
import AddEventScreen from "../screens/Dashboard/Events/AddEvent";
import AddLocalDetailsScreen from "../screens/Dashboard/Local/AddLocalDetails";
import AddLocalScreen from "../screens/Dashboard/Local/AddLocal";
import AddedLocalScreen from "../screens/Dashboard/Local/AddedLocalScreen";
import BottomTabNavigator from "./BottomTabNavigator";
import EditEventScreen from "../screens/Dashboard/Events/EditEventScreen";
import EventsScreen from "../screens/Dashboard/Events/EventsScreen";
import LocalScreen from "../screens/Dashboard/Local/Local";
import LocalsScreen from "../screens/Dashboard/Local/LocalsScreen";
import NotificationsScreen from "../screens/Dashboard/Notifications/NotificationScreen";
import ProfileScreen from "../screens/Dashboard/Profile/Profile";
import TicketScreen from "../screens/Dashboard/Tickets/TicketScreen";

function DashboardNavigator() {
  const location = useLocation();

  const routeName = location.pathname.split("/")[2];

  const showBottomTabNavigator = [
    "locals",
    "events",
    "notifications",
    "tickets",
  ].includes(routeName);

  return (
    <>
      <Routes>
        <Route path="/locals" element={<LocalsScreen />} />
        <Route path="/local/add" element={<AddLocalScreen />} />
        <Route path="/local/add/details" element={<AddLocalDetailsScreen />} />
        <Route path="/local/success" element={<AddedLocalScreen />} />
        <Route path="/local/:id" element={<LocalScreen />} />

        <Route path="/events" element={<EventsScreen />} />
        <Route path="/event/edit" element={<EditEventScreen />} />
        <Route path="/event/add" element={<AddEventScreen />} />
        <Route path="/event/add/more" element={<AddEventMoreScreen />} />

        <Route path="/notifications" element={<NotificationsScreen />} />

        <Route path="/tickets" element={<TicketScreen />} />

        <Route path="/profile" element={<ProfileScreen />} />
      </Routes>
      {showBottomTabNavigator && <BottomTabNavigator />}
    </>
  );
}

export default DashboardNavigator;
