// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 15px;
  color: #222; /* Icon color */
}

.custom-input {
  width: 100%;
  padding: 15px 15px 15px 15px; /* Adjust padding to accommodate the icon */
  font-size: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
}

.custom-input.with-icon {
  padding-left: 45px; /* Additional left padding when there is an icon */
}

.custom-input::placeholder {
  color: #d0d0d0; /* Placeholder text color */
  opacity: 1; /* Ensure full opacity */
}
`, "",{"version":3,"sources":["webpack://./src/components/Input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW,EAAE,eAAe;AAC9B;;AAEA;EACE,WAAW;EACX,4BAA4B,EAAE,2CAA2C;EACzE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB,EAAE,kDAAkD;AACxE;;AAEA;EACE,cAAc,EAAE,2BAA2B;EAC3C,UAAU,EAAE,wBAAwB;AACtC","sourcesContent":[".input-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.input-wrapper {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.input-icon {\r\n  position: absolute;\r\n  left: 15px;\r\n  color: #222; /* Icon color */\r\n}\r\n\r\n.custom-input {\r\n  width: 100%;\r\n  padding: 15px 15px 15px 15px; /* Adjust padding to accommodate the icon */\r\n  font-size: 16px;\r\n  border: 1px solid #d0d0d0;\r\n  border-radius: 6px;\r\n}\r\n\r\n.custom-input.with-icon {\r\n  padding-left: 45px; /* Additional left padding when there is an icon */\r\n}\r\n\r\n.custom-input::placeholder {\r\n  color: #d0d0d0; /* Placeholder text color */\r\n  opacity: 1; /* Ensure full opacity */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
