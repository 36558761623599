// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-screen {
  text-align: center;
  background-color: #f7f7f7;
  min-height: 100vh;
}

.header {
  color: #fff;
  background-color: #4bc592;
  padding: 18px;
  font-size: 24px;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  background-color: #fff;
  border: none;
  padding: 15px 0;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  color: #555;
  border-radius: 0;
}

.tab.active {
  border-bottom: 2px solid #4bc592;
}

.tab-content {
  margin-top: 40px;
  text-align: center;
  flex: auto;
  justify-content: center;
  align-items: center;
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4bc592;
}

.placeholder-content p {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

.plus-icon {
  font-size: 40px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background-color: #4bc592;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/Events/EventsScreen.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,+BAA+B;EAC/B,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".events-screen {\r\n  text-align: center;\r\n  background-color: #f7f7f7;\r\n  min-height: 100vh;\r\n}\r\n\r\n.header {\r\n  color: #fff;\r\n  background-color: #4bc592;\r\n  padding: 18px;\r\n  font-size: 24px;\r\n}\r\n\r\n.tabs {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.tab {\r\n  background-color: #fff;\r\n  border: none;\r\n  padding: 15px 0;\r\n  font-size: 16px;\r\n  font-family: \"Lato\", sans-serif;\r\n  cursor: pointer;\r\n  color: #555;\r\n  border-radius: 0;\r\n}\r\n\r\n.tab.active {\r\n  border-bottom: 2px solid #4bc592;\r\n}\r\n\r\n.tab-content {\r\n  margin-top: 40px;\r\n  text-align: center;\r\n  flex: auto;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.placeholder-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  color: #4bc592;\r\n}\r\n\r\n.placeholder-content p {\r\n  margin-top: 10px;\r\n  font-size: 18px;\r\n  color: #333;\r\n}\r\n\r\n.plus-icon {\r\n  font-size: 40px;\r\n  height: 40px;\r\n  width: 40px;\r\n  border-radius: 40px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  color: #fff;\r\n  cursor: pointer;\r\n  background-color: #4bc592;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
