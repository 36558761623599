import React, { useState } from "react";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import { useNavigate } from "react-router-dom";
import { fontScale, scaleWidth } from "../../utils/responsive";

import ownerImage from "../../assets/imgs/owner.png";
import organizerImage from "../../assets/imgs/organizer.png";

function TypeScreen() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);

  const handleSelection = (type) => {
    setSelectedType(type);
  };

  return (
    <div
      style={{
        textAlign: "center",
        paddingRight: scaleWidth(40),
        paddingLeft: scaleWidth(40),
      }}
    >
      <Spacer height={20} />
      <h1
        style={{
          color: "#4BC592",
          textAlign: "left",
          fontSize: scaleWidth(26),
        }}
      >
        Seleziona la tipologia di profilo più adatta a te
      </h1>
      <Spacer height={30} />

      <div
        onClick={() => handleSelection("owner")}
        style={{
          border:
            selectedType === "owner"
              ? "2px solid #4BC592"
              : "2px solid #D9D9D9",
          borderRadius: "22px",
          padding: scaleWidth(20),
          cursor: "pointer",
        }}
      >
        <img
          src={ownerImage}
          alt="Proprietario di un locale"
          style={{ width: scaleWidth(163), height: "auto" }}
        />
      </div>
      <Spacer height={10} />
      <h2
        style={{
          fontSize: fontScale(20),
          fontWeight: selectedType === "owner" ? 900 : 400,
        }}
      >
        Proprietario di un locale
      </h2>
      <Spacer height={30} />

      <div
        onClick={() => handleSelection("organizator")}
        style={{
          border:
            selectedType === "organizator"
              ? "2px solid #4BC592"
              : "2px solid #D9D9D9",
          borderRadius: "22px",
          padding: scaleWidth(20),
          cursor: "pointer",
        }}
      >
        <img
          src={organizerImage}
          alt="Organizzatore di eventi"
          style={{ width: scaleWidth(163), height: "auto" }}
        />
      </div>

      <Spacer height={10} />
      <h2
        style={{
          fontSize: fontScale(20),
          fontWeight: selectedType === "organizator" ? 900 : 400,
        }}
      >
        Organizzatore di eventi
      </h2>

      <Spacer height={50} />
      <Button
        type="action"
        onClick={() => {
          if (selectedType) {
            navigate("/signup", { state: { accountType: selectedType } });
          } else {
            alert("Per favore, seleziona un tipo di account.");
          }
        }}
      >
        INIZIA
      </Button>
      <Spacer height={10} />
    </div>
  );
}

export default TypeScreen;
