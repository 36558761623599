import React, { useEffect, useState } from "react";
import { fontScale, scaleWidth } from "../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import authService from "../../services/authService"; // Import the authService
import { createItem } from "../../services/apiService";

const SignupDetailsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { accountType, email, password } = location.state || {};

  const [formValues, setFormValues] = useState({
    companyName: "",
    vatNumber: "",
    adminName: "",
    phoneNumber: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid =
      formValues.companyName.trim() !== "" &&
      formValues.vatNumber.trim() !== "" &&
      formValues.phoneNumber.trim() !== "";

    setIsFormValid(isValid);
  }, [formValues]);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Create user in Firebase Authentication using authService
      const { user } = await authService.signUpWithEmail(email, password);

      // Prepare data to be sent to the backend
      const payload = {
        firebaseUid: user.uid, // Use Firebase UID for backend association
        role: accountType,
        email,
        vat: formValues.vatNumber,
        name: formValues.companyName,
        administrator: formValues.adminName,
        phone: formValues.phoneNumber,
      };

      // Make POST request to backend
      try {
        await createItem("hosts", payload);
      } catch (error) {
        console.error(error);
        alert("Errore durante il salvatggio sul Database");
      }
    } catch (error) {
      console.error("Error during Firebase account creation:", error);
      alert("There was an error creating your account. Please try again.");
    }
  };

  return (
    <div
      style={{
        paddingRight: scaleWidth(32),
        paddingLeft: scaleWidth(32),
      }}
    >
      <Spacer height={20} />
      <h1
        style={{
          color: "#222",
          textAlign: "left",
          fontSize: scaleWidth(26),
        }}
      >
        Inserisci le informazioni principali dell’Host
      </h1>
      <Spacer height={10} />
      <p style={{ color: "#717171", fontSize: fontScale(17) }}>
        Tutti dati che ci fornirai saranno utilizzati esclusivamente per la tua
        registrazione sulla nostra app e nessuna informazione verrà condivisa
        con terze parti.
      </p>
      <Spacer height={40} />
      <Input
        label={"Ragione sociale*"}
        placeholder='Es. "Mario Rossi SPA"'
        type="text"
        value={formValues.companyName}
        onChange={(e) => handleInputChange("companyName", e.target.value)}
      />
      <Spacer height={30} />
      <Input
        label={"P.IVA*"}
        placeholder="Inserisci P.IVA"
        type="text"
        value={formValues.vatNumber}
        onChange={(e) => handleInputChange("vatNumber", e.target.value)}
      />
      <Spacer height={30} />
      <Input
        label={"Nome Amministratore"}
        placeholder="Nome e Cognome"
        type="text"
        value={formValues.adminName}
        onChange={(e) => handleInputChange("adminName", e.target.value)}
      />
      <Spacer height={30} />
      <Input
        label={"Numero di telefono*"}
        placeholder="Inserisci il numero di telefono"
        type="tel"
        value={formValues.phoneNumber}
        onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
      />
      <Spacer height={30} />
      <div style={{ display: "flex" }}>
        <Button type="info" onClick={() => navigate("/signup")}>
          Indietro
        </Button>
        <Spacer width={20} />
        <Button type="action" onClick={handleSubmit} disabled={!isFormValid}>
          Prosegui
        </Button>
      </div>
    </div>
  );
};

export default SignupDetailsScreen;
