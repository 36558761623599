const colors = {
  primaryDark: "#272132",
  grey: "#707070",
  lightGrey: "#F3F3F3",
  white: "#FFFFFF",
  primaryPurple: "#8235BF",
  secondaryPurple: "#A544F2",
  lightPurple: "#DBBEF2",
};

export default colors;
