const categories = {
  BUSINESS: [
    "Industria",
    "Automotive",
    "Arredo",
    "Packaging",
    "Logistica",
    "Edilizia",
    "Agricoltura",
    "Giardinaggio",
    "Ecologia",
    "Cosmesi",
    "Estetica",
    "Igiene",
    "Enologia",
    "Gastronomia",
    "HO.RE.CA.",
    "Abbigliamento",
    "Sportivo",
    "Gaming",
    "Turismo",
    "Prodotti PET",
  ],
  SPORT: [
    "Basket",
    "Calcio",
    "Pallavolo",
    "Danza",
    "Equitazione",
    "Surf",
    "Baseball",
    "Football",
    "Golf",
    "Arrampicata",
    "Hockey su prato",
    "Hockey",
    "Cricket",
    "Tiro con l'arco",
    "Atletica",
    "Badminton",
    "Motori",
    "Padel",
    "Pugilato",
    "Bowling",
    "Tennis",
    "Biliardino",
    "Biliardo",
    "Beach Volley",
    "Sci",
    "Snowboard",
    "E-Sport",
    "Nuoto",
    "Ciclismo",
    "Fitness e BB",
    "Rugby",
    "Freccette",
    "Pallamano",
    "Pallanuoto",
    "Scherma",
    "Scacchi",
  ],
  CULTURA: [
    "Cinema",
    "Arte Moderna",
    "Videogiochi",
    "Scultura",
    "Scienza",
    "Architettura",
    "Radiofonia",
    "Fotografia",
    "Politica",
    "Interattivi",
    "Storici",
    "Fashion & Moda",
    "Letteratura",
    "Fumetti",
    "Ambiente",
    "Poesia",
    "Cucina",
    "Teatro",
    "Recitazione",
    "Collezione",
    "Modellismo",
  ],
  MUSICA: [
    "Rock",
    "Classica",
    "Hip Hop",
    "House",
    "Pop",
    "Techno",
    "Metal",
    "EDM",
    "Tech House",
    "Urban",
    "D&B",
    "Straniera",
    "Indie Pop",
    "Swing",
    "Funk",
    "Jazz",
    "Blues",
    "Soul",
    "Latino",
    "Reggaeton",
  ],
  FESTIVITÀ: ["Sagre", "Anniversari", "Patroni", "Cerimonie"],
  RISTORO: [
    "Gastronomia",
    "Vino",
    "Etnico",
    "Degustazione",
    "Spirits",
    "Buffets",
    "Prod. Tipici",
    "Prod. Artigianali",
    "Biologico",
    "Cocktail",
    "Analogici",
    "Birra",
    "Panini",
  ],
};

export default categories;
