import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import Spacer from "../../components/Spacer";
import { fontScale, scaleWidth } from "../../utils/responsive";

// Example images, replace with your actual images
import welcomeImage1 from "../../assets/imgs/welcome.png";
import welcomeImage2 from "../../assets/imgs/welcome.png";
import welcomeImage3 from "../../assets/imgs/welcome.png";

function WelcomeScreen() {
  const navigate = useNavigate();

  const slides = [
    {
      image: welcomeImage1,
      title: "Benvenuto nell'app Evento Host",
      description:
        "Se possiedi un locale o un'attività, l'Account Host ti offre il modo perfetto per promuoverlo come sede dei tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta!",
    },
    {
      image: welcomeImage2,
      title: "Promuovi il tuo locale",
      description:
        "Se possiedi un locale o un'attività, l'Account Host ti offre il modo perfetto per promuoverlo come sede dei tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta!",
    },
    {
      image: welcomeImage3,
      title: "Attira una folla entusiasta!",
      description:
        "Se possiedi un locale o un'attività, l'Account Host ti offre il modo perfetto per promuoverlo come sede dei tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [slides.length]);

  return (
    <div
      style={{
        textAlign: "center",
        paddingRight: scaleWidth(40),
        paddingLeft: scaleWidth(40),
      }}
    >
      <Spacer height={20} />
      <h1
        style={{
          color: "#4BC592",
          fontSize: scaleWidth(34),
        }}
      >
        Benvenuto nell'app Evento Host
      </h1>
      <Spacer height={20} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={slides[currentIndex].image}
          alt="Welcome"
          style={{ width: scaleWidth(240), height: "auto" }}
        />
      </div>
      <Spacer height={30} />
      <h2
        style={{
          fontSize: fontScale(27),
          textAlign: "left",
        }}
      >
        {slides[currentIndex].title}
      </h2>
      <Spacer height={10} />
      <p style={{ fontSize: fontScale(20), textAlign: "left" }}>
        {slides[currentIndex].description}
      </p>
      <Spacer height={30} />
      <div>
        {slides.map((_, index) => (
          <span
            key={index}
            style={{
              height: "8px",
              width: "8px",
              backgroundColor: index === currentIndex ? "#4BC592" : "#D9D9D9",
              borderRadius: "50%",
              display: "inline-block",
              margin: "0 5px",
            }}
          />
        ))}
      </div>
      <Spacer height={30} />
      <Button type="action" onClick={() => navigate("/type")}>
        CREA IL TUO ACCOUNT
      </Button>
      <Spacer height={10} />
      <Button type="secondary" onClick={() => navigate("/login")}>
        ACCEDI
      </Button>
      <Spacer height={10} />
    </div>
  );
}

export default WelcomeScreen;
