import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";

import Autocomplete from "react-google-autocomplete";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import fbicon from "../../../assets/icons/fb.png";
import instaIcon from "../../../assets/icons/ig.png";
import tiktokIcon from "../../../assets/icons/tt.png";
import { useNavigate } from "react-router-dom";
import webIcon from "../../../assets/icons/web.png";

const AddLocalScreen = () => {
  const navigate = useNavigate();

  // Form data and error states
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    address: "",
    addressObj: null,
    lat: 0,
    lng: 0,
    website: "",
    facebook: "",
    instagram: "",
    tiktok: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validation logic
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Nome locale è richiesto";
    if (!formData.type) newErrors.type = "Tipologia di locale è richiesta";
    if (!formData.address) newErrors.address = "Indirizzo è richiesto";
    return newErrors;
  };

  const handleNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set validation errors
      return;
    }

    const { addressObj, ...serializableFormData } = formData;
    navigate("/dashboard/local/add/details", { state: serializableFormData });
  };

  return (
    <div>
      <Header title={"Aggiungi locale"} />

      <div
        style={{
          paddingRight: scaleWidth(30),
          paddingLeft: scaleWidth(35),
          paddingTop: scaleWidth(31),
          paddingBottom: scaleWidth(10),
        }}
      >
        <h2
          style={{
            fontSize: fontScale(28),
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Inserisci le informazioni per registrare il tuo locale
        </h2>
        <Spacer height={46} />

        <div>
          <Input
            label="Nome locale"
            placeholder="Inserisci nome"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          <Spacer height={30} />

          <Label text="Tipologia di locale" />
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            style={{
              color: formData.type === "" ? "#D0D0D0" : "#222",
              backgroundColor: "#fff",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              width: "100%",
              borderStyle: "solid",
            }}
          >
            <option value="" disabled>
              Seleziona tipologia
            </option>
            <option value="disco">Discoteca</option>
            <option value="bar">Bar</option>
            <option value="lounge">Lounge Bar</option>
            <option value="other">Altro</option>
          </select>
          {errors.type && <span style={{ color: "red" }}>{errors.type}</span>}
          <Spacer height={30} />

          <Label text="Indirizzo locale" />
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE}
            style={{
              color: formData.address ? "#222" : "#A0A0A0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              borderStyle: "solid",
            }}
            onPlaceSelected={(place) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: place.formatted_address,
                addressObj: place,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              }));
            }}
            language="it"
            debounce={500}
            options={{
              types: ["address"],
              componentRestrictions: { country: "it" },
            }}
          />
          {errors.address && (
            <span style={{ color: "red" }}>{errors.address}</span>
          )}
          <Spacer height={30} />

          <Input
            label="Sito Web"
            placeholder="www.sitoweb.it"
            type="text"
            name="website"
            value={formData.website}
            onChange={handleChange}
            icon={webIcon}
          />
          <Spacer height={30} />
          <Input
            label="Social Network"
            placeholder="facebook.com/username"
            type="text"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
            icon={fbicon}
          />
          <Spacer height={5} />
          <Input
            label=""
            placeholder="instagram.com/username"
            type="text"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
            icon={instaIcon}
          />
          <Spacer height={5} />
          <Input
            label=""
            placeholder="tiktok.com/@username"
            type="text"
            name="tiktok"
            value={formData.tiktok}
            onChange={handleChange}
            icon={tiktokIcon}
          />
          <Spacer height={300} />

          <div
            style={{
              display: "flex",
              position: "fixed",
              height: scaleWidth(55),
              paddingLeft: scaleWidth(23),
              paddingTop: scaleWidth(12),
              paddingBottom: scaleWidth(13),
              paddingRight: scaleWidth(23),
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#fff",
              borderTop: "1px solid #e8e8e8",
            }}
          >
            <Button type="back" onClick={() => navigate("/dashboard/locals")}>
              Indietro
            </Button>
            <Spacer width={30} />
            <Button type="success" onClick={handleNext}>
              Prosegui
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocalScreen;
