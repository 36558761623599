import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Autocomplete from "react-google-autocomplete";
import Button from "../../../components/Button";
import CustomRadioButton from "../../../components/CustomRadioButton";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import { createItem } from "../../../services/apiService";

const AddEventScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const local = location.state?.local || {};
  console.log("Local:", local.id);

  const [formData, setFormData] = useState({
    name: "",
    host: "",
    localId: local.id || "",
    address: local.address || "",
    addressObj: null, // Add addressObj to store the full address object
    lat: local.lat || "", // latitude from local
    lng: local.lng || "", // longitude from local
    date: "",
    endDate: "",
    startTime: "",
    endTime: "",
    price: "",
    parkingPrice: undefined,
    category: "",
    subcategory: "",
    description: "",
    imgUrl: "",
    // parking: [],
    isParkingSecure: true,
    parkingAddress: "",
    locationType: "same",
    hasEndDate: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    if (value === "same") {
      setFormData({
        ...formData,
        locationType: value,
        address: local.address,
        lat: local.lat,
        lng: local.lng,
        addressObj: null, // Reset addressObj when using the same location as the local
      });
    } else {
      setFormData({
        ...formData,
        locationType: value,
        address: "",
        lat: "",
        lng: "",
        addressObj: null, // Reset addressObj for different location
      });
    }
  };

  const handleParkingSecured = (checked) => {
    console.log("Parking secured:", checked);
    setFormData({ ...formData, isParkingSecure: checked });
  };

  const handleParkingFree = (e) => {
    const { checked } = e.target;
    setFormData({
      ...formData,
      parkingPrice: checked ? 0 : 1,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const eventData = {
      name: formData.name,
      hostId: formData.host,
      localId: local.id,
      address: formData.address,
      lat: formData.lat, // Include latitude
      lng: formData.lng, // Include longitude
      addressObj: formData.addressObj, // Include the full address object
      date: formData.date,
      endDate: formData.hasEndDate ? formData.endDate : null,
      startTime: formData.startTime,
      endTime: formData.endTime,
      price: parseFloat(formData.price),
      parkingPrice: parseFloat(formData.parkingPrice),
      category: formData.category,
      subcategory: formData.subcategory,
      description: formData.description,
      imgUrl: formData.imgUrl,
      parking: formData.parking,
      isParkingSecure: formData.isParkingSecure,
    };

    createItem("events", eventData)
      .then((response) => {
        console.log("Event created successfully:", response.data);
        navigate("/dashboard/event/add/more", {
          state: { eventData: response.data },
        });
      })
      .catch((error) => {
        console.error("Error creating event:", error);
      });
  };

  return (
    <div>
      <Header title={"Inserisci evento 1/2"} hasBackButton={true} />

      <div
        style={{
          paddingRight: scaleWidth(30),
          paddingLeft: scaleWidth(35),
          paddingTop: scaleWidth(31),
          paddingBottom: scaleWidth(10),
        }}
      >
        <h2
          style={{
            fontSize: fontScale(28),
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Inserisci le informazioni sull'evento da pubblicare
        </h2>
        <Spacer height={10} />
        <p style={{ fontSize: fontScale(20) }}>
          Condividere i dettagli sul proprietario del locale ci permette di
          garantire un'esperienza sicura e affidabile a tutti i nostri utenti.
        </p>
        <Spacer height={40} />

        <form onSubmit={handleSubmit}>
          <Input
            label="Titolo dell'evento*"
            placeholder="Inserisci titolo"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />

          <Spacer height={35} />

          <hr
            style={{
              borderColor: "#efefef",
              borderWidth: "1px",
              width: "100%",
            }}
          />

          <div>
            {local.id !== "none" && (
              <>
                <Spacer height={25} />
                <Label text="Luogo dell'evento*" />
                <Spacer height={20} />
                <CustomRadioButton
                  id="sameLocation"
                  name="locationType"
                  value="same"
                  checked={formData.locationType === "same"}
                  onChange={handleLocationChange}
                  label="Il luogo dell'evento coincide con quello del locale"
                />
                <Spacer height={22} />
                <CustomRadioButton
                  id="differentLocation"
                  name="locationType"
                  value="different"
                  checked={formData.locationType === "different"}
                  onChange={handleLocationChange}
                  label="Il luogo dell'evento è differente da quello del locale"
                />
              </>
            )}
          </div>
          {(formData.locationType === "different" || local.id === "none") && (
            <>
              <Spacer height={20} />
              <Label text="Indirizzo dell'evento" />
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE}
                onPlaceSelected={(place) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    address: place.formatted_address,
                    addressObj: place, // Save the full address object
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  }));
                }}
                language="it"
                debounce={500}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "it" },
                }}
              />
            </>
          )}

          <Spacer height={40} />

          <hr
            style={{
              borderColor: "#efefef",
              borderWidth: "1px",
              width: "100%",
            }}
          />

          <Spacer height={32} />
          <Input
            label="Giorno/i di svolgimento*"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              id="hasEndDate"
              name="hasEndDate"
              checked={formData.hasEndDate}
              onChange={handleChange}
              style={{ width: "auto", marginRight: scaleWidth(10) }}
            />
            <label htmlFor="hasEndDate" style={{ fontSize: fontScale(17) }}>
              L'evento si conclude il giorno stesso
            </label>
          </div>
          {!formData.hasEndDate && (
            <>
              <Spacer height={20} />
              <Input
                label="Data di fine"
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </>
          )}

          <Spacer height={31} />

          <hr
            style={{
              borderColor: "#efefef",
              borderWidth: "1px",
              width: "100%",
            }}
          />
          <Spacer height={20} />

          <Label text="Indica la durata dell'evento*" />
          <Spacer width={13} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Spacer width={10} />

            <p style={{ fontSize: fontScale(17) }}>Inizio</p>
            <Spacer width={20} />
            <Input
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
            />
            <Spacer width={20} />
            <p style={{ fontSize: fontScale(17) }}>fine</p>
            <Spacer width={20} />

            <Input
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
            />
          </div>
          <Spacer height={31} />

          <hr
            style={{
              borderColor: "#efefef",
              borderWidth: "1px",
              width: "100%",
            }}
          />
          <Spacer height={20} />
          <Input
            label="Prezzo*"
            placeholder="Inserisci il prezzo dell'evento"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
          <Spacer height={30} />
          <hr
            style={{
              borderColor: "#efefef",
              borderWidth: "1px",
              width: "100%",
            }}
          />
          <Spacer height={30} />

          <Label text="Indicazioni parcheggio (se presente)" />
          <Spacer height={20} />
          <Label text="Prezzo del parcheggio" />
          <Input
            label=""
            placeholder="Inserisci il prezzo del parcheggio"
            type="number"
            name="parkingPrice"
            value={formData.parkingPrice}
            onChange={handleChange}
            disabled={formData.parkingPrice && formData.parkingPrice === 0}
          />
          <label
            htmlFor="custodito"
            style={{ display: "block", cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <input
                type="checkbox"
                id="custodito"
                name="custodito"
                checked={formData.isParkingSecure}
                onChange={(e) => handleParkingSecured(e.target.checked)}
                style={{ width: "auto", marginRight: scaleWidth(10) }}
              />
              <span style={{ fontSize: fontScale(19) }}>Custodito</span>
            </div>
          </label>
          <Spacer height={20} />
          {/* <label
            htmlFor="custodito"
            style={{ display: "block", cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <input
                type="checkbox"
                id="custodito"
                name="custodito"
                checked={formData.isParkingSecure}
                onChange={(e) => handleParkingSecured(e.target.checked)}
                style={{ width: "auto", marginRight: scaleWidth(10) }}
              />
              <span style={{ fontSize: fontScale(19) }}>Custodito</span>
            </div>
          </label> */}
          <Spacer height={10} />
          <label
            htmlFor="gratuito"
            style={{ display: "block", cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <input
                type="checkbox"
                id="gratuito"
                name="gratuito"
                checked={formData.parkingPrice === 0}
                onChange={handleParkingFree}
                style={{ width: "auto", marginRight: scaleWidth(10) }}
              />
              <span style={{ fontSize: fontScale(19) }}>Gratuito</span>
            </div>
          </label>
          <Spacer height={10} />
          <Label text="Inserisci l'indirizzo del parcheggio" />
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE}
            onPlaceSelected={(place) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                parkingAddress: place.formatted_address,
              }));
            }}
            language="it"
            debounce={500}
            options={{
              types: ["address"],
              componentRestrictions: { country: "it" },
            }}
          />

          <Spacer height={20} />
          <div
            style={{
              display: "flex",
              position: "fixed",
              height: scaleWidth(55),
              paddingLeft: scaleWidth(23),
              paddingTop: scaleWidth(12),
              paddingBottom: scaleWidth(13),
              paddingRight: scaleWidth(23),
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#fff",
              borderTop: "1px solid #e8e8e8",
            }}
          >
            <Button type="back" onClick={() => navigate(-1)}>
              Indietro
            </Button>
            <Spacer width={10} />
            <Button type="success">Prosegui</Button>
          </div>
          <Spacer height={80} />
        </form>
      </div>
    </div>
  );
};

export default AddEventScreen;
