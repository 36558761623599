import "./Input.css"; // Import the external CSS file for styling

import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for showing/hiding password
import React, { useState } from "react";
import { fontScale, scaleWidth } from "../utils/responsive";

import Label from "./Label";
import PropTypes from "prop-types";

const Input = ({
  label,
  placeholder,
  icon,
  type = "text", // Default input type is text
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="input-container">
      {label && <Label text={label} />}
      <div className="input-wrapper" style={{ position: "relative" }}>
        {typeof icon === "string" ? (
          <img
            src={icon}
            alt="Icon"
            className="input-icon"
            style={{
              position: "absolute",
              left: scaleWidth(16),
              top: "50%",
              transform: "translateY(-50%)",
              width: scaleWidth(24),
              height: scaleWidth(24),
              borderRight: "1px solid #D0D0D0",
              paddingRight: scaleWidth(12),
            }}
          />
        ) : (
          icon && <span className="input-icon">{icon}</span>
        )}
        <input
          className={`custom-input ${icon ? "with-icon" : ""}`}
          style={{
            fontSize: fontScale(20),
            borderColor: "#D0D0D0",
            borderWidth: 0.5,
            borderRadius: 6,
            color: "#000", // Input text color
            paddingLeft: icon ? scaleWidth(64) : scaleWidth(15), // Adjust padding for left icon
          }}
          type={isPasswordVisible ? "text" : type}
          placeholder={placeholder}
          {...props}
        />
        {type === "password" && (
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: scaleWidth(15),
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "#000",
            }}
          >
            {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string, // Accept a string (image URL or path) as an icon
  ]),
  type: PropTypes.string, // Include the type prop
};

export default Input;
