import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fontScale, scaleWidth } from "../utils/responsive";

import authService from "../services/authService";
import bigliettiIcon from "../assets/icons/tickets.png";
import eventiIcon from "../assets/icons/events.png";
import { filterItems } from "../services/apiService";
import hostIcon from "../assets/icons/host.png";
import localiIcon from "../assets/icons/locals.png";
import notificheIcon from "../assets/icons/bell.png";

const BottomTabNavigator = () => {
  const location = useLocation();
  const [userRole, setUserRole] = useState(null); // State to store the user role
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchUserRole = async () => {
      console.log("Fetching user role...");
      try {
        const currentUser = authService.getCurrentUser();
        if (currentUser) {
          const host = await filterItems("hosts", {
            attribute: "firebaseUid",
            value: currentUser.uid,
          });
          setUserRole(host.data[0].role);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false after fetch
      }
    };

    fetchUserRole();
  }, []); // Empty dependency array ensures this runs once on mount

  if (loading) {
    // Optionally render a loading spinner or message here
    return <div>Loading...</div>;
  }

  // Styles
  const styles = {
    bottomTabNavigator: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      backgroundColor: "#ffffff",
      boxShadow: "0 -1px 10px rgba(0, 0, 0, 0.1)",
      padding: "10px 0",
    },
    tabItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textDecoration: "none",
      color: "#222",
    },
    tabItemActive: {
      color: "#19b774",
    },
    tabIconImage: {
      width: scaleWidth(24),
      height: scaleWidth(24),
    },
    tabIconImageActive: {
      filter:
        "brightness(0) saturate(100%) invert(50%) sepia(100%) saturate(500%) hue-rotate(90deg) brightness(90%) contrast(90%)",
    },
    tabText: {
      fontSize: fontScale(14),
      marginTop: scaleWidth(3),
      fontFamily: '"Lato", sans-serif',
      fontWeight: 700,
    },
  };

  // Dynamically generate the menu items based on user role
  const menuItems = [
    userRole === "owner"
      ? { name: "Locali", path: "/dashboard/locals", icon: localiIcon }
      : { name: "Eventi", path: "/dashboard/events", icon: eventiIcon },
    { name: "Biglietti", path: "/dashboard/tickets", icon: bigliettiIcon },
    {
      name: "Notifiche",
      path: "/dashboard/notifications",
      icon: notificheIcon,
    },
    { name: "Host", path: "/dashboard/profile", icon: hostIcon },
  ];

  return (
    <div style={styles.bottomTabNavigator}>
      {menuItems.map((item) => (
        <Link
          key={item.name}
          to={item.path}
          style={{
            ...styles.tabItem,
            ...(location.pathname === item.path && styles.tabItemActive),
          }}
        >
          <div>
            <img
              src={item.icon}
              alt={item.name}
              style={{
                ...styles.tabIconImage,
                ...(location.pathname === item.path &&
                  styles.tabIconImageActive),
              }}
            />
          </div>
          <div style={styles.tabText}>{item.name}</div>
        </Link>
      ))}
    </div>
  );
};

export default BottomTabNavigator;
