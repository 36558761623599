import React, { useState } from "react";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import authService from "../../services/authService"; // Import the authService
import logoImage from "../../assets/imgs/logo.png";
import { scaleWidth } from "../../utils/responsive";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      // Use authService to sign in with email and password
      const { user } = await authService.signInWithEmail(email, password);

      // After successful login, you can navigate to the dashboard or any other page
      console.log("User logged in:", user);
      navigate("/dashboard/locals");
    } catch (error) {
      // Handle authentication errors
      console.error("Login error:", error);
      setError("Email o password non corretti. Riprova.");
    }
  };

  return (
    <div
      style={{
        paddingRight: scaleWidth(32),
        paddingLeft: scaleWidth(32),
      }}
    >
      <Spacer height={20} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logoImage} alt="Logo" />
      </div>
      <Spacer height={10} />
      <h1
        style={{
          color: "#222",
          textAlign: "center",
          fontSize: scaleWidth(28),
        }}
      >
        Accedi su Evento Host
      </h1>
      <Spacer height={5} />
      <p
        style={{
          color: "#717171",
          textAlign: "center",
          fontSize: scaleWidth(19),
        }}
      >
        Crea un profilo, scopri nuovi eventi, segui i tuoi host preferiti e
        altro ancora.
      </p>
      <Spacer height={50} />
      <Input
        label={"Email"}
        placeholder="Inserisci la tua mail"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Spacer height={20} />
      <Input
        label={"Password"}
        placeholder="Inserisci la tua password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Spacer height={20} />

      {error && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            fontSize: scaleWidth(15),
          }}
        >
          {error}
        </p>
      )}

      <Spacer height={30} />
      <Button type="action" onClick={handleLogin}>
        ACCEDI
      </Button>

      <Spacer height={20} />
      <Button
        type="info"
        onClick={() => {
          console.log("Password dimenticata");
          // Here you can navigate to a "Forgot Password" screen or handle password reset
        }}
      >
        Non ricordi la password?
      </Button>
      <Spacer height={20} />
      <Button
        type="info"
        onClick={() => {
          navigate("/type");
        }}
      >
        Non hai un account? Registrati
      </Button>
      <Spacer height={30} />

      <p
        style={{
          color: "#717171",
          textAlign: "center",
          fontSize: scaleWidth(13),
        }}
      >
        Continuando, accetti i nostri{" "}
        <strong style={{ color: "#222" }}>Termini di Servizio</strong> e
        confermi di aver letto la nostra{" "}
        <strong style={{ color: "#222" }}>Informativa sulla Privacy</strong> per
        sapere in che modo raccogliamo, trattiamo e condividiamo i tuoi dati e
        la nostra
        <strong style={{ color: "#222" }}> Informativa sui Cookie</strong> per
        sapere in che modo utilizziamo i cookie.
      </p>
    </div>
  );
};

export default LoginScreen;
